/* eslint-disable react/prop-types */
import { graphql } from 'gatsby';
import React from 'react';

import ServiceContent from 'components/pages/services/service-content';
import MainLayout from 'layouts/main';

const Services = (props) => {
  const {
    data: {
      mdx: { html, frontmatter },
      allMdx: { nodes: items },
    },
  } = props;

  const pageMetadata = {
    title: frontmatter.title,
  };

  return (
    <MainLayout pageMetadata={pageMetadata}>
      <ServiceContent html={html} label={frontmatter.label} items={items} />
    </MainLayout>
  );
};

export default Services;
export const query = graphql`
  query ($id: String!, $categoryFilter: String!) {
    mdx(id: { eq: $id }) {
      html: body
      frontmatter {
        title
        label
      }
      slug
    }
    allMdx(
      filter: {
        frontmatter: { category: { eq: $categoryFilter } }
        fileAbsolutePath: { regex: "/src/service-posts/" }
      }
    ) {
      nodes {
        frontmatter {
          title
          description
          duration
          price
          category
        }
        slug
      }
    }
  }
`;
