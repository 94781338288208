import { MDXProvider } from '@mdx-js/react';
import classNames from 'classnames';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import PropTypes from 'prop-types';
import React from 'react';

import Cards, { cardItemPropTypes } from 'components/pages/services/service-content/cards/cards';
import ContactForm from 'components/shared/contact-form';
import Container from 'components/shared/container';

const components = { ContactForm };
const ServiceContent = ({ html, label, items }) => (
  <section className="pt-8 pb-14 sm:pt-10 sm:pb-20 lg:pt-16 lg:pb-30 xl:pt-28 xl:pb-40">
    <Container size="md">
      <div
        className={classNames(
          'prose font-medium prose-h1:mb-3.5 prose-p:my-3 first:prose-p:mt-0 prose-li:my-1.5',
          'sm:prose-lg sm:prose-h1:mb-5 sm:prose-p:my-4 sm:prose-li:my-1.5',
          'lg:prose-xl lg:prose-h1:mb-6 lg:prose-p:my-4 lg:prose-li:my-1.5',
          'xl:prose-h1:mb-7',
          !items.length ? 'mx-auto max-w-[800px]' : '!max-w-none'
        )}
      >
        <span className="page-label mb-4">{label}</span>
        <MDXProvider components={components}>
          <MDXRenderer>{html}</MDXRenderer>
        </MDXProvider>
      </div>
      {!!items.length && <Cards items={items} />}
    </Container>
  </section>
);

ServiceContent.propTypes = {
  html: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({ ...cardItemPropTypes })).isRequired,
};

export default ServiceContent;
