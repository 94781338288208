import PropTypes from 'prop-types';
import React from 'react';

import Button from 'components/shared/button';
import Heading from 'components/shared/heading';

const Cards = ({ items }) => (
  <ul className="mt-8 grid auto-rows-fr grid-cols-1 gap-5 sm:mt-9 sm:grid-cols-2 lg:gap-7 xl:mt-10 xl:grid-cols-3 xl:gap-8">
    {items.map(
      ({ frontmatter: { title, description, duration, price, category }, slug }, index) => (
        <li className="flex flex-col border border-gray-1 p-5 pt-6" key={index}>
          <Heading theme="black" tag="h2" size="xs">
            {title}
          </Heading>
          <p className="mt-2.5 text-base leading-normal sm:text-lg sm:leading-normal">
            {description}
          </p>
          <div className="mt-auto flex flex-col">
            <div className="mt-4.5 flex justify-between text-base font-semibold">
              <span>{duration}</span>
              <span>{price}</span>
            </div>
            <Button
              className="mt-4 justify-center"
              theme="tertiary"
              color="gray"
              to={`/${category.toLocaleLowerCase()}/${slug}`}
            >
              Discover
            </Button>
          </div>
        </li>
      )
    )}
  </ul>
);

export const cardItemPropTypes = {
  frontmatter: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    duration: PropTypes.string.isRequired,
    price: PropTypes.string.isRequired,
    category: PropTypes.string.isRequired,
  }).isRequired,
  slug: PropTypes.string.isRequired,
};

Cards.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({ ...cardItemPropTypes })).isRequired,
};

export default Cards;
